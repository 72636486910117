import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Paper, ListSubheader } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { graphql } from "gatsby";

import ButtonLoading from '../components/ButtonLoading';
import Layout from '../components/Layout';

import * as queryParam from '../utils/query';

import { actions as userActions } from '../services/user';
import { actions as inquiryActions } from '../services/inquiries';


import LeaseTermsModal from '../components/LeaseTermsModal';
import { useQueryParams, BooleanParam } from 'use-query-params';

import {
  LeaseLength,
  LeaseMileage,
  DownPayment,
  CreditScore,
  prepandDefaultExteriorColor,
  prepandDefaultInteriorColor,
  CarMake,
  CarModel,
  ModelTrim,
  getExteriorColorIndex,
} from "leasemojo-common";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { InquirySaveArgs } from "../types";
import { trackEvent } from "../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginTop: theme.spacing(3),
      },
      title: {
        marginBottom: theme.spacing(2),
      },
      info: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap-reverse',
        justifyContent: 'center',
        [ theme.breakpoints.up('sm') ]: {
          justifyContent: 'space-between',
        },
        marginBottom: theme.spacing(5),
      },
      leftSide: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [ theme.breakpoints.up('sm') ]: {
          marginRight: theme.spacing(2),
        }
      },
      rightSide: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [ theme.breakpoints.up('sm') ]: {
          marginLeft: theme.spacing(2),
        }
      },
      image: {
        width: '100%',
        maxWidth: 300,
        height: 150,
        [ theme.breakpoints.up('sm') ]: {
          height: 200,
          maxWidth: 400,
        },
      },
      specs: {
        width: '100%',
        minWidth: 300,
        border: '1px solid #eee',
      },
      divider: {
        margin: theme.spacing(1, 0),
      },

      qa: {
        border: '1px solid #eee',
        padding: theme.spacing(2),
      },

      question: {

      },
      answer: {

      }
    }
  ),
);

interface Props {
  data: {
    car: CarMake;
    model: CarModel;
    trim: ModelTrim;
    site: {
      siteMetadata: {
        shortName: string;
      }
    }
  }
}


const ConfigSummary: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { car, model, site } = data;

  const trim = Object.assign({}, data.trim) as ModelTrim;

  trim.exteriorColors = prepandDefaultExteriorColor(trim.exteriorColors, trim.defaultColors.exteriorColorId);
  trim.interiorColors = prepandDefaultInteriorColor(trim.interiorColors, trim.defaultColors.interiorColorId);

  const [ exteriorColor, setExteriorColor ] = useState(0);
  const [ interiorColor, setInteriorColor ] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const loading = useSelector((state: RootState) => state.inquiries.submitInquiryLoading);

  const [ params, setParams ] = useQueryParams({
    'terms': BooleanParam
  });

  const closeTerms = () => {
    setParams({
      terms: false
    }, 'replace')
  }


  useEffect(() => {
    const exterior = queryParam.get('exterior');
    if (typeof exterior !== 'undefined') {
      setExteriorColor(exterior);
    }
    const interior = queryParam.get('interior');
    if (typeof interior !== 'undefined') {
      setInteriorColor(interior);
    }
  }, []);


  const openLeaseTerms = () => {
    trackEvent('pickCarLeaseTerms');
    setParams({
      terms: true
    }, 'pushIn')
  }

  const onSubmitLeaseTerms = async (creditScore: CreditScore, leaseLength: LeaseLength, mileage: LeaseMileage, downPayment: DownPayment, zip?: number) => {

    closeTerms();
    dispatch(userActions.saveCreditScore(creditScore));

    const zipCode = zip || user.zipCode || user.user?.zipCode;

    const data: InquirySaveArgs = {
      car: car.id,
      model: model.originalId,
      trim: trim.originalId,
      exteriorColor: trim.exteriorColors[ exteriorColor ].id,
      interiorColor: trim.interiorColors[ interiorColor ].id,
      zip: zipCode || 0,
      creditScore: creditScore,
      leaseLength: leaseLength,
      mileage: mileage,
      downPayment: downPayment
    };
    dispatch(inquiryActions.submitInquiry(data));
  }

  const imgUrl = trim.exteriorColors[ exteriorColor ].images.lg || model.placeholderImage;
  const title = `${model.modelYear} ${car.name} ${model.name}`;

  let priceNum = trim.msrp + trim.exteriorColors[ exteriorColor ].msrp + trim.interiorColors[ interiorColor ].msrp;
  let price = priceNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const seoTitle = `${data.car.name} ${data.model.name} - ${data.trim.name}`;
  const ogImage = process.env.CDN_BASE_URL_CARS + "/" + imgUrl;

  return (
    <Layout title={seoTitle} ogImage={ogImage}>
      <Container className={classes.root} maxWidth="md">
        <div className={classes.title}>
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
          <Typography>
            {trim.name}
          </Typography>
        </div>
        <div className={classes.info}>

          <div className={classes.leftSide}>
            <Paper className={classes.specs} elevation={0}>
              <List>
                <ListSubheader disableSticky color="primary">Standard Features & Specs</ListSubheader>
                <ListItem>
                  <ListItemText primary={trim.engine[ 0 ]} secondary="Engine" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={trim.driveTrain} secondary="Drive Train" />
                </ListItem>
                <Divider light />
                <ListSubheader disableSticky color="primary">Colors</ListSubheader>
                <ListItem>
                  <ListItemText primary={trim.exteriorColors[ exteriorColor ].name} secondary="Exterior" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={trim.interiorColors[ interiorColor ].name} secondary="Interior" />
                </ListItem>
              </List>
            </Paper>
          </div>

          <div className={classes.rightSide}>
            <img className={classes.image} src={process.env.CDN_BASE_URL_CARS + "/" + imgUrl} alt={`${title} ${trim.name}`} />
            <Typography>MSRP ${price}</Typography>
            <Typography paragraph variant="caption">including options</Typography>
            <ButtonLoading variant="contained" color="secondary" size="large" onClick={openLeaseTerms} loading={loading}>Get Lease Offers</ButtonLoading>
          </div>
        </div>


        <Typography variant="h6">How this works?</Typography>
        <Typography paragraph variant="body2">When you submit your request, nearby dealers and brokers will send you the best offers possible. You then choose who to contact to get your deal. It's that simple.</Typography>

        <Typography variant="h6">Is this better than going directly to dealership?</Typography>
        <Typography paragraph variant="body2">Ohh yes :) On average our customers get 20% lower monthly payments. With {site.siteMetadata.shortName} you directly get offers from multiple dealerships/brokers which guarantees that the price will be much better.</Typography>

        <Typography variant="h6">It is fully anonymous</Typography>
        <Typography paragraph variant="body2">Your information will never be shared with dealerships, you decide which dealership to contact to get your new car, based on their rating and price.</Typography>

      </Container>
      <LeaseTermsModal open={Boolean(params.terms)} onClose={closeTerms} onSubmit={onSubmitLeaseTerms} />
    </Layout>
  );
};

export default ConfigSummary;

export const query = graphql`
  query($carId: String!, $modelId: String!, $trimId: String!) {
    site {
      siteMetadata {
        shortName
      }
    }
    car(id: {eq: $carId}) {
      id,
      name
      logoUrl
      slug
    }
    model(id: {eq: $modelId}) {
      id
      originalId,
      imageUrl
      placeholderImage
      name
      slug
      modelYear,
    }
    trim(id: {eq: $trimId}) {
      id
      originalId
      name
      msrp
      defaultColors {
        exteriorColorId
        interiorColorId
      }
      exteriorColors {
        images {
          lg
          md
        }
        msrp
        name
        rgbHex
        id
      }
      interiorColors {
        id
        msrp
        name
      }
      slug
      engine
      driveTrain
    }
  }
`
