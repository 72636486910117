import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, FormHelperText, Typography } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
      },

      text: {
        marginLeft: theme.spacing(1),
        fontSize: 12,
        color: theme.palette.text.secondary
      },
      icon: {
        // color: theme.palette.text.secondary,
        color: 'rgba(0, 0, 0, 0.3)',
      }

    }
  ),
);


interface Props {
  text: string;
}

const FormHintText: React.FC<Props> = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <HelpIcon className={classes.icon} fontSize="small"/> */}
      <Typography className={classes.text}>
        {text}
      </Typography>
    </div>
  );
};

export default FormHintText;