import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, FormHelperText, TextField } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransitionProps } from '@material-ui/core/transitions';

import izValidZip from '../utils/isValidZip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import FormHintText from '../components/FormHintText';

import { actions as userActions } from '../services/user';

import {
  CreditScore,
  LeaseLength,
  LeaseMileage,
  DownPayment,
  CreditScoreLabels,
  LeaseLengthLabels,
  LeaseMileageLabels,
  DownPaymentLabels,
} from "leasemojo-common";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      title: {
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        marginBottom: theme.spacing(3),
      },
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (creditScore: CreditScore, leaseLength: LeaseLength, mileage: LeaseMileage, downPayment: DownPayment, zip?: number) => void;
}

const LeaseTermsModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const savedCreditScore = useSelector((state: RootState) => state.user.creditScore);

  const [ creditScore, setCreditScore ] = useState<CreditScore | string>(savedCreditScore || '');
  const [ mileage, setMileage ] = useState<LeaseMileage>('not_sure');
  const [ downPayment, setDownPayment ] = useState<DownPayment>('not_sure');
  const [ leaseDuration, setLeaseDuration ] = useState<LeaseLength>('not_sure');

  const initialZip = useSelector((state: RootState) => state.user.zipCode);
  const [ zip, setZip ] = useState(initialZip ? initialZip.toString() : '');

  const [ errors, setErrors ] = useState({
    creditScore: '',
    zip: '',
  });

  const updateCreditScore = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCreditScore(event.target.value as string);
  }
  const updateMileage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMileage(event.target.value as LeaseMileage);
  }
  const updateDownPayment = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDownPayment(event.target.value as DownPayment);
  }
  const updateLeaseDuration = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLeaseDuration(event.target.value as LeaseLength);
  }
  const updateZip = (event: React.ChangeEvent<{ value: unknown }>) => {
    setZip(event.target.value as LeaseLength);
  }

  const submit = async () => {
    let zipCode;

    if (creditScore === '') {
      setErrors({
        ...errors,
        creditScore: 'Credit score is required.'
      });
      return;
    }
    zipCode = validateZip();
    if (!initialZip && !zipCode) {
      setErrors({
        ...errors,
        zip: `${zip} is not valid LA zip code`
      });
      return;
    }

    onSubmit(creditScore as CreditScore, leaseDuration, mileage, downPayment, zipCode);
  }

  const validateZip = () => {
    const _zip = parseInt(zip);
    if (!izValidZip(_zip)) {
      return 0;

    }
    else {
      dispatch(userActions.saveZipCode(_zip));
      return _zip;
    }
  }


  const menuCreditScores: React.ReactElement[] = [];
  CreditScoreLabels.forEach((value, key) => {
    menuCreditScores.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });
  const menuLeaseLength: React.ReactElement[] = [];
  LeaseLengthLabels.forEach((value, key) => {
    menuLeaseLength.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });
  const menuMileage: React.ReactElement[] = [];
  LeaseMileageLabels.forEach((value, key) => {
    menuMileage.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });
  const menuDownPayment: React.ReactElement[] = [];
  DownPaymentLabels.forEach((value, key) => {
    menuDownPayment.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      className={classes.root}
      maxWidth="xs"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.title}>
        Lease Terms
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>

        {
          !initialZip ? (
            <div className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="zip-code"
                label="Zip code"
                name="zip-code"
                autoComplete="zip"
                error={errors.zip !== ''}
                helperText={errors.zip}
                value={zip}
                onChange={updateZip}
              />
            </div>
          ) : null
        }

        <FormControl className={classes.formControl} variant="outlined" error={errors.creditScore !== ''}>
          <InputLabel htmlFor="credit-score">My Credit Score *</InputLabel>
          <Select
            value={creditScore}
            onChange={updateCreditScore}
            labelWidth={115}
            inputProps={{
              name: 'credit-score',
              id: 'credit-score',
            }}
          >
            {
              menuCreditScores.map(menu => menu)
            }
          </Select>
          {
            errors.creditScore ? <FormHelperText>{errors.creditScore}</FormHelperText> : null
          }
          <FormHintText text="This will used so agents and dealers can give you more accurate pricing." />
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="lease-length">Lease Length</InputLabel>
          <Select
            value={leaseDuration}
            onChange={updateLeaseDuration}
            labelWidth={100}
            inputProps={{
              name: 'lease-length',
              id: 'lease-length',
            }}
          >
            {
              menuLeaseLength.map(menu => menu)
            }
          </Select>
          <FormHintText text="At the end of the lease you will have an option to buy the vehicle." />
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="mileage">Yearly Mileage</InputLabel>
          <Select
            value={mileage}
            onChange={updateMileage}
            labelWidth={105}
            inputProps={{
              name: 'mileage',
              id: 'mileage',
            }}
          >
            {
              menuMileage.map(menu => menu)
            }
          </Select>
          <FormHintText text="This is the yearly mileage allowance on your new vehicle." />
        </FormControl>


        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="down_payment">Down payment (initial out of pocket)</InputLabel>
          <Select
            value={downPayment}
            onChange={updateDownPayment}
            labelWidth={265}
            inputProps={{
              name: 'down_payment',
              id: 'down_payment',
            }}
          >
            {
              menuDownPayment.map(menu => menu)
            }
          </Select>
          <FormHintText text="Down payment reduces your monthly payments and usually includes first month payment and first years registration fee." />
        </FormControl>


        <Button variant="contained" color="primary" onClick={submit}>
          Submit My Inquiry
        </Button>

      </DialogContent>
    </Dialog>
  );
};

export default LeaseTermsModal;