import { navigate } from '@reach/router';

export const get = (param: string): number | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(param);
  if (value !== null) {
    return parseInt(value);
  }
}


export const set = (param: string, value: number) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(param, value.toString());

  const query = urlParams.toString();

  if (query !== '') {
    navigate(window.location.pathname + '?' + query, {
      replace: true
    });
  }
}

